import { IProduct } from "interfaces/products.interfaces";

export const fbDataLayerPageVisit = () => {
	try {
		if (window.dataLayer) {
			window.dataLayer.filter(
				(item: any) => item.event === "virtualPageview"
			);

			window.dataLayer.push({ event: "virtualPageview" });
		}
	} catch (error) {
		return false;
	}

	return true;
};

export const fbDataLayerProductDetail = (product: IProduct) => {
	try {
		//we're going to assume that every time the first variant is preselected on the detail page
		//this way we can fill the variant field.
		let variant = "";
		if (product.variants.length > 0) {
			const firstVariant = product.variants[0];
			variant = firstVariant.sku;
		}

		window.dataLayer.push({
			event: "productDetail",
			ecommerce: {
				currencyCode: "USD",
				detail: {
					products: [
						{
							name: product.content.title, // Name or ID is required.
							id: product.sourceEntryId,
							price: product.variants[0].price,
							brand: product.vendor,
							category: product.productType,
							variant: variant,
						},
					],
				},
			},
		});
	} catch (error) {
		return false;
	}

	return true;
};
