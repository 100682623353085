import styles from "./NotFound.module.scss";
import RC1Image from "components/RC1Image/RC1Image";

import { motion, Variants } from "framer-motion";
import Search from "@/components/Search/Search";

const NotFound = (): JSX.Element => {
	const variantTitle: Variants = {
		visible: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.2,
				type: "spring",
			},
		},
		hidden: { opacity: 0, x: -50 },
	};
	const variantP: Variants = {
		visible: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.5,
				type: "spring",
			},
		},
		hidden: { opacity: 0, x: -50 },
	};
	const variantSearch: Variants = {
		visible: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.9,
				type: "spring",
			},
		},
		hidden: { opacity: 0, x: -50 },
	};

	const variantImg: Variants = {
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 1,
				type: "spring",
			},
		},
		hidden: { opacity: 0, y: -50 },
	};
	const variantImgMobile: Variants = {
		visible: {
			opacity: 1,
			transition: {
				delay: 0.3,
				type: "spring",
			},
		},
		hidden: { opacity: 0 },
	};

	return (
		<section className={`container py-5 ${styles.notFound}`}>
			<article className="row">
				<div className={`col-md-6 ${styles.sectionR}`}>
					<motion.h2
						variants={variantTitle}
						initial="hidden"
						animate="visible"
					>
						WHOA! OUR BAD.
					</motion.h2>
					<motion.div
						variants={variantImgMobile}
						initial="hidden"
						animate="visible"
						className={`d-md-none ${styles.image}`}
					>
						<RC1Image
							src="https://images.ctfassets.net/ixt8mzbymyrb/4IZd2EtyPVj6Il7yKrXq8N/0f2d5838c0a48a9712bef6270c722155/lft.png"
							layout="responsive"
							width={620}
							height={470}
							alt="ltf"
							loading="lazy"
						/>
					</motion.div>
					<motion.p
						variants={variantP}
						initial="hidden"
						animate="visible"
					>
						Product not found. We are searching the warehouse. Take
						a shot for yourself.
					</motion.p>
					<motion.div
						variants={variantSearch}
						initial="visible"
						animate="visible"
						className={styles.searchBar}
					>
						<Search />
					</motion.div>
				</div>
				<div
					className={`col-md-6 d-none d-md-block ${styles.sectionL}`}
				>
					<motion.div
						variants={variantImg}
						initial="hidden"
						animate="visible"
						className={styles.image}
					>
						<RC1Image
							src="https://images.ctfassets.net/ixt8mzbymyrb/4IZd2EtyPVj6Il7yKrXq8N/0f2d5838c0a48a9712bef6270c722155/lft.png"
							layout="responsive"
							width={620}
							height={470}
							alt="ltf"
							loading="lazy"
						/>
					</motion.div>
				</div>
			</article>
		</section>
	);
};

export default NotFound;
