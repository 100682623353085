import { FC } from "react";
import dynamic from "next/dynamic";
import styles from "./OrderComponent.module.scss";
import { IContent } from "@/interfaces/pageContent";

const SubHeader: any = dynamic(
	() => import("@/components/SubHeader/SubHeader"),
	{
		ssr: false,
	}
);

const EliteAthletes: any = dynamic(
	() => import("@/components/EliteAthletes/EliteAthletes"),
	{
		ssr: false,
	}
);

const LovedSection: any = dynamic(
	() => import("@/components/LovedSection/LovedSection"),
	{
		ssr: false,
	}
);

const SlideCustomVideo: any = dynamic(
	() => import("@/components/SlideCustomVideo/SlideCustomVideo"),
	{
		ssr: false,
	}
);

const ProductList: any = dynamic(
	() => import("@/modules/HomePage/components/ProductList/ProductList"),
	{
		ssr: false,
	}
);

const SlidingBanner: any = dynamic(
	() => import("@/components/SlidingBanner/SlidingBanner"),
	{
		ssr: false,
	}
);

const MainGrid: any = dynamic(() => import("@/components/MainGrid/MainGrid"), {
	ssr: false,
});

const BigSection: any = dynamic(
	() => import("@/components/BigSection/BigSection"),
	{
		ssr: false,
	}
);

const CollectionListHomePage: any = dynamic(
	() => import("@/components/CollectionListHomePage/CollectionListHomePage"),
	{
		ssr: false,
	}
);

interface OrderComponentProps {
	content: IContent;
}

const OrderComponent: FC<OrderComponentProps> = ({ content }) => {
	return (
		<>
			{content.type === "vendors" && (
				<SubHeader vendors={content?.fields} />
			)}
			{content.type === "homepageBanner" && (
				<SlidingBanner bannerData={content?.fields} />
			)}
			{content.type === "collection" && (
				<section className="rc1-container">
					<div className="blockHeader">
						<h2>MUST HAVE REDCON1 BEST-SELLERS</h2>
					</div>
					<ProductList products={content?.fields?.products} />
				</section>
			)}
			{content.type === "collectionCardList" && (
				<article className={styles.collectionCardList}>
					<MainGrid collectionCards={content?.fields} />
				</article>
			)}
			{content.type === "collectionInterfaceList" && (
				<CollectionListHomePage collectionList={content?.fields} />
			)}
			{content.type === "videoList" && content?.fields?.section === 1 && (
				<section className={`mt-30`}>
					<article className="col-md-12">
						<SlideCustomVideo
							videoList={content?.fields?.videos}
							showHeader={true}
							centerMode={true}
							centerPadding="5%"
						/>
					</article>
				</section>
			)}
			{content.type === "subBanner" && (
				<div>
					<BigSection item={content.fields} />
				</div>
			)}

			{content.type === "athleteGallery" && (
				<section className="container-fluid mt-4">
					<article className="row">
						<div className="col-md-12 px-0">
							<EliteAthletes data={content?.fields} />
						</div>
					</article>
				</section>
			)}
			{content.type === "videoList" && content?.fields?.section === 2 && (
				<section className="container-fluid mt-5">
					<article className="row">
						<div className="col-md-12 px-0">
							<LovedSection videos={content?.fields} />
						</div>
					</article>
				</section>
			)}
		</>
	);
};

export default OrderComponent;
