import { useEffect, useState } from "react";
import styles from "./BackToTopBtn.module.scss";

const BackToTopBtn = () => {
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		let isMounted = true;
		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 600) {
				if (isMounted) {
					setShowButton(true);
				}
			} else {
				if (isMounted) {
					setShowButton(false);
				}
			}
		});
		return () => {
			isMounted = false;
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<>
			{showButton && (
				<button onClick={scrollToTop} className={styles.backToTop}>
					<i className={styles.arrowUp}></i>
				</button>
			)}
		</>
	);
};

export default BackToTopBtn;
