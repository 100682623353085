import React, { useEffect } from "react";
import Head from "next/head";

import NotFound from "@/modules/NotFound/NotFound";
import BackToTopBtn from "@/components/BackToTopBtn/BackToTopBtn";

import {
	fetchProductsForCollection,
	fetchDataForCollectionInterface,
} from "utils/fetchDataInObject";
import { fbDataLayerPageVisit } from "@/helpers/fbDataLayer";
import OrderComponent from "@/modules/LayoutPage/components/OrderComponents/OrderComponents";
import { IContent } from "@/types/pageContent";
import client from "@/services/contentfulClient";

type IndexPageProps = {
	content: any;
};

const Index = ({ content }: IndexPageProps) => {
	useEffect(() => {
		if (typeof window !== "undefined") {
			fbDataLayerPageVisit();
		}
	}, []);

	if (content) {
		const homeContent = content?.fields?.content;
		return (
			<>
				<Head>
					<title>
						REDCON1 - Fastest Growing Sports Supplement Brand In
						History
					</title>
					{content?.fields?.seoTitle ? (
						<>
							<meta
								key="title"
								name="title"
								content={content?.fields?.seoTitle}
							/>
						</>
					) : null}
					{content?.fields?.seoDescription ? (
						<>
							<meta
								name="description"
								key="description"
								content={content?.fields?.seoDescription}
							/>
						</>
					) : null}
				</Head>
				{homeContent ? (
					<>
						{homeContent.map((cont: IContent, index: number) => (
							<OrderComponent key={index} content={cont} />
						))}
						<BackToTopBtn />
					</>
				) : (
					<div>
						<p>loading....</p>
					</div>
				)}
			</>
		);
	} else {
		return <NotFound />;
	}
};

export async function getStaticProps() {
	let contentContentful: any = {};

	try {
		contentContentful = await client.getEntries({
			"fields.handle": "homepage_us_retail",
			/* eslint-disable camelcase */
			content_type: "storePage",
			include: 10,
		});

		if (contentContentful.items[0].fields.content) {
			let sections = contentContentful.items[0].fields.content;
			for (let section of sections) {
				// Adds transformed data to match Nacelle data structure
				section.type = section.sys.contentType.sys.id;
				if (section.type === "collection") {
					// Change to contentfulContent.fields.content[0].sys.contentType.sys.id
					await fetchProductsForCollection(section);
				}
				if (section.type === "collectionInterfaceList") {
					// Change to contentfulContent.fields.content[0].sys.contentType.sys.id
					await fetchDataForCollectionInterface(section);
				}
			}
		}
		return {
			props: {
				content: contentContentful?.items[0],
			},
		};
	} catch (error) {
		return {
			props: {
				content: [],
			},
		};
	}
}

export default Index;
